// @ts-nocheck

import { SupabaseClient } from "@supabase/supabase-js";

type EventType = "logged" | "save_profile" | "chat" | "answer";

const Logger = {
  log: async (
    userId: string,
    type: EventType,
    params: any,
    client: SupabaseClient
  ) => {
    return null;
    if (type === "logged") {
      return;
    }
    try {
      const ip = await Logger.getIP();
      const fingerprint = Logger.getFingerprint();
      const logItem = {
        user_id: userId,
        type,
        params,
        tracking: {
          ip,
          ...fingerprint,
        },
      };

      await client.from("log").insert(logItem);
    } catch (e) {
      console.error("Log error", e);
    }
  },
  getFingerprint: () => {
    return;
    return {
      userAgent: navigator.userAgent,
      language: navigator.language,
      platform: navigator.platform,
      cookieEnabled: navigator.cookieEnabled,
      timezoneOffset: new Date().getTimezoneOffset(),
    };
  },
  getIP: async () => {
    return;
    // Get from local storage first
    const ipFingerprint = localStorage.getItem("ipFingerprint");
    const expired = localStorage.getItem("ipFingerprintExpired");
    if (ipFingerprint && expired && parseInt(expired) > new Date().getTime()) {
      return ipFingerprint;
    } else {
      // Get from API
      const response = await fetch("https://www.cloudflare.com/cdn-cgi/trace");
      const text = await response.text();
      // Expire in 5 minutes
      const newExpired = new Date().getTime() + 5 * 60 * 1000;
      localStorage.setItem("ipFingerprint", text);
      localStorage.setItem("ipFingerprintExpired", newExpired.toString());
      return ipFingerprint;
    }
  },
};

export { Logger };
