export type PlanItem = {
  id: number;
  type: "poll" | "words-cloud" | "rate" | "quiz" | "race";
  name: string;
  correct?: number;
  items: {
    id: number;
    name: string;
  }[];
  // For backend
  action?: string;
};

const PLAN: PlanItem[] = [
  {
    id: 10,
    type: "words-cloud",
    name: "Какой подарок лучше всех?",
    items: [],
  },
  {
    id: 21,
    type: "quiz",
    name: "Что за фильм?",
    items: [
      {
        id: 1,
        name: "Семьянин",
      },
      {
        id: 2,
        name: "Дневник Бриджит Джонс",
      },
      {
        id: 3,
        name: "Один дома",
      },
      {
        id: 4,
        name: "Саманта: Каникулы американской девочки",
      },
    ],
  },
  {
    id: 22,
    type: "quiz",
    name: "Кавер на музыку из какого фильма?",
    items: [
      {
        id: 1,
        name: "Ирония судьбы, или С легким паром!",
      },
      {
        id: 2,
        name: "Чародеи",
      },
      {
        id: 3,
        name: "Зигзаг удачи",
      },
      {
        id: 4,
        name: "Карьера Димы Горина",
      },
    ],
  },
  {
    id: 23,
    type: "quiz",
    name: "Что за фильм?",
    items: [
      {
        id: 1,
        name: "Вечера на хуторе близ Диканьки",
      },
      {
        id: 2,
        name: "Новогодние приключения Маши и Вити",
      },
      {
        id: 3,
        name: "Морозко",
      },
      {
        id: 4,
        name: "Снежная королева",
      },
    ],
  },
  {
    id: 24,
    type: "quiz",
    name: "Кавер на музыку из какого фильма?",
    items: [
      {
        id: 1,
        name: "Умка на елке",
      },
      {
        id: 2,
        name: "Падал прошлогодний снег",
      },
      {
        id: 3,
        name: "Зима в Простоквашино",
      },
      {
        id: 4,
        name: "12 месяцев",
      },
    ],
  },
  {
    id: 25,
    type: "quiz",
    name: "Что за фильм?",
    items: [
      {
        id: 1,
        name: "Смертельное оружие",
      },
      {
        id: 2,
        name: "Крепкий орешек",
      },
      {
        id: 3,
        name: "Духи Рождества",
      },
      {
        id: 4,
        name: "Бэтмен возвращается",
      },
    ],
  },
  {
    id: 26,
    type: "quiz",
    name: "Кавер на музыку из какого фильма?",
    items: [
      {
        id: 1,
        name: "Золушка",
      },
      {
        id: 2,
        name: "Щелкунчик",
      },
      {
        id: 3,
        name: "Ночь перед рождеством",
      },
      {
        id: 4,
        name: "12 месяцев",
      },
    ],
  },
  {
    id: 27,
    type: "quiz",
    name: "Что за фильм?",
    items: [
      {
        id: 1,
        name: "Новогодний переполох",
      },
      {
        id: 2,
        name: "Ёлки",
      },
      {
        id: 3,
        name: "Тариф Новогодний",
      },
      {
        id: 4,
        name: "Полицейский с Рублёвки. Новогодний беспредел",
      },
    ],
  },
  {
    id: 28,
    type: "quiz",
    name: "Кавер на музыку из какого фильма?",
    items: [
      {
        id: 1,
        name: "Кавказская пленница",
      },
      {
        id: 2,
        name: "Иван Васильевич меняет профессию",
      },
      {
        id: 3,
        name: "Старый Новый год",
      },
      {
        id: 4,
        name: "Операция Ы",
      },
    ],
  },
  {
    id: 29,
    type: "quiz",
    name: "Что за фильм?",
    items: [
      {
        id: 1,
        name: "Гринч — похититель Рождества",
      },
      {
        id: 2,
        name: "Пингвины мистера Поппера",
      },
      {
        id: 3,
        name: "Плохой Санта",
      },
      {
        id: 4,
        name: "Чарли и шоколадная фабрика",
      },
    ],
  },
  {
    id: 30,
    type: "quiz",
    name: "Кавер на музыку из какого фильма?",
    items: [
      {
        id: 1,
        name: "Иван Васильевич меняет профессию",
      },
      {
        id: 2,
        name: "Бриллиантовая рука",
      },
      {
        id: 3,
        name: "Джентльмены удачи",
      },
      {
        id: 4,
        name: "Операция Ы",
      },
    ],
  },
  {
    id: 40,
    type: "words-cloud",
    name: "Ваш любимый новогодний фильм",
    items: [],
  },
  {
    id: 41,
    type: "poll",
    name: "Что слушаем дальше?",
    items: [
      {
        id: 1,
        name: "Будем жечь под Maneskin 🤟",
      },
      {
        id: 2,
        name: "Врубай Bon Jovi 🎸",
      },
    ],
  },
  {
    id: 200,
    type: "race",
    name: "Новогодняя гонка #1",
    items: [],
  },
  {
    id: 201,
    type: "race",
    name: "Новогодняя гонка #2",
    items: [],
  },
  {
    id: 51,
    type: "quiz",
    name: "По легенде, мадам Клико взяла проволочку из своего корсажа, чтобы удержать пробку в горлышке бутылки шампанского",
    items: [
      {
        id: 1,
        name: "Правда",
      },
      {
        id: 2,
        name: "Ложь",
      },
    ],
  },
  {
    id: 52,
    type: "quiz",
    name: "Во Франции оливье называют «русским салатом»",
    items: [
      {
        id: 1,
        name: "Правда",
      },
      {
        id: 2,
        name: "Ложь",
      },
    ],
  },
  {
    id: 53,
    type: "quiz",
    name: "Впервые селёдка под шубой была подана одним из рестораторов Москвы в 1952 году",
    items: [
      {
        id: 1,
        name: "Правда",
      },
      {
        id: 2,
        name: "Ложь",
      },
    ],
  },
  {
    id: 54,
    type: "quiz",
    name: "На Руси холодец готовили из остатков мясных блюд на следующий день после празднования Нового года, чтобы накормить слуг",
    items: [
      {
        id: 1,
        name: "Правда",
      },
      {
        id: 2,
        name: "Ложь",
      },
    ],
  },
  {
    id: 55,
    type: "quiz",
    name: "Самая крепкая водка производится не в России, а в Норвегии",
    items: [
      {
        id: 1,
        name: "Правда",
      },
      {
        id: 2,
        name: "Ложь",
      },
    ],
  },
  {
    id: 56,
    type: "quiz",
    name: "Памятник шпротам находится в Калининграде",
    items: [
      {
        id: 1,
        name: "Правда",
      },
      {
        id: 2,
        name: "Ложь",
      },
    ],
  },
  {
    id: 57,
    type: "quiz",
    name: "Лучше покупать красную икру, изготовленную поздней весной",
    items: [
      {
        id: 1,
        name: "Правда",
      },
      {
        id: 2,
        name: "Ложь",
      },
    ],
  },
  {
    id: 58,
    type: "quiz",
    name: "Фразу «Какая гадость эта ваша заливная рыба!» придумал Эльдар Рязанов",
    items: [
      {
        id: 1,
        name: "Правда",
      },
      {
        id: 2,
        name: "Ложь",
      },
    ],
  },
  {
    id: 59,
    type: "quiz",
    name: "Торт «Наполеон» назван в честь Наполеона Бонапарта заядлого кулинара и любителя женщин, который частно угощал их разными эксклюзивными десертами",
    items: [
      {
        id: 1,
        name: "Правда",
      },
      {
        id: 2,
        name: "Ложь",
      },
    ],
  },
  {
    id: 60,
    type: "quiz",
    name: "Британские учёные доказали, что отвар из мандариновых корок повышают потенцию",
    items: [
      {
        id: 1,
        name: "Правда",
      },
      {
        id: 2,
        name: "Ложь",
      },
    ],
  },
  {
    id: 70,
    type: "words-cloud",
    name: "Ваше любимое блюдо",
    items: [],
  },
  {
    id: 71,
    type: "poll",
    name: "Что послушаем?",
    items: [
      {
        id: 1,
        name: "Солнце Монако ☀️",
      },
      {
        id: 2,
        name: "Мое сердце 🤎",
      },
    ],
  },
  {
    id: 72,
    type: "poll",
    name: "Чем продолжим?",
    items: [
      {
        id: 1,
        name: "Зажигаем под Зверей 🦁",
      },
      {
        id: 2,
        name: "Танцуем вместе с Zivert 💃",
      },
    ],
  },
  {
    id: 73,
    type: "poll",
    name: "Какое поздравление понравилось больше всех?",
    items: [
      {
        id: 1,
        name: "Машечкина Анна Сергеевна",
      },
      {
        id: 2,
        name: "Копаев Дмитрий Анатольевич",
      },
      {
        id: 3,
        name: "Карасев Игорь Викторович",
      },
      {
        id: 4,
        name: "Сергеева Ольга Юрьевна",
      },
      {
        id: 5,
        name: "Живодеров Вадим Витальевич",
      },
    ],
  },
  {
    id: 80,
    type: "poll",
    name: "Вернемся в 90-е вместе с группой...",
    items: [
      {
        id: 1,
        name: "Жуки 🪲",
      },
      {
        id: 2,
        name: "Леприконсы 🎡",
      },
    ],
  },

  {
    id: 81,
    type: "poll",
    name: "А что же дальше?",
    items: [
      {
        id: 1,
        name: "«Руки вверх» форева! 🙌",
      },
      {
        id: 2,
        name: "Подпеваем группе «Серебро» 👯‍♀️",
      },
    ],
  },
  {
    id: 82,
    type: "words-cloud",
    name: "Как вам группа?",
    items: [],
  },
  {
    id: 91,
    type: "quiz",
    name: "Что рекламируют?",
    items: [
      {
        id: 1,
        name: "Интернет-магазин",
      },
      {
        id: 2,
        name: "Службу доставки",
      },
      {
        id: 3,
        name: "Автомобильную компанию",
      },
      {
        id: 4,
        name: "Услуги клининга",
      },
    ],
  },
  {
    id: 92,
    type: "quiz",
    name: "Что рекламируют?",
    items: [
      {
        id: 1,
        name: "Презервативы",
      },
      {
        id: 2,
        name: "Конфеты",
      },
      {
        id: 3,
        name: "Мебель",
      },
      {
        id: 4,
        name: "Пенсионный фонд",
      },
    ],
  },
  {
    id: 93,
    type: "quiz",
    name: "Что рекламируют?",
    items: [
      {
        id: 1,
        name: "Фейерверк",
      },
      {
        id: 2,
        name: "Океанариум",
      },
      {
        id: 3,
        name: "Макароны",
      },
      {
        id: 4,
        name: "Батарейки",
      },
    ],
  },
  {
    id: 94,
    type: "quiz",
    name: "Что рекламируют?",
    items: [
      {
        id: 1,
        name: "Всемирный фонд дикой природы",
      },
      {
        id: 2,
        name: "Авиакомпания",
      },
      {
        id: 3,
        name: "Школа приёмных родителей",
      },
      {
        id: 4,
        name: "Институт детской психологии",
      },
    ],
  },
  {
    id: 95,
    type: "quiz",
    name: "Что рекламируют?",
    items: [
      {
        id: 1,
        name: "Службу поиска персонала",
      },
      {
        id: 2,
        name: "Курсы повышения квалификации",
      },
      {
        id: 3,
        name: "Магазин спортивной одежды",
      },
      {
        id: 4,
        name: "Услуги по прочистке дымохода",
      },
    ],
  },
  {
    id: 96,
    type: "quiz",
    name: "Что рекламируют?",
    items: [
      {
        id: 1,
        name: "Службу доставки",
      },
      {
        id: 2,
        name: "Напиток",
      },
      {
        id: 3,
        name: "Сервис упаковки",
      },
      {
        id: 4,
        name: "Конфеты",
      },
    ],
  },
  {
    id: 97,
    type: "quiz",
    name: "Что рекламируют?",
    items: [
      {
        id: 1,
        name: "Корм для кошек",
      },
      {
        id: 2,
        name: "Лекарство",
      },
      {
        id: 3,
        name: "Электроинструмент",
      },
      {
        id: 4,
        name: "Напиток",
      },
    ],
  },
  {
    id: 98,
    type: "quiz",
    name: "Что рекламируют?",
    items: [
      {
        id: 1,
        name: "Канцелярские товары",
      },
      {
        id: 2,
        name: "Стиральный порошок",
      },
      {
        id: 3,
        name: "Бренд мужской одежды",
      },
      {
        id: 4,
        name: "Сервис для тайм-менеджмента",
      },
    ],
  },
  {
    id: 99,
    type: "quiz",
    name: "Что рекламируют?",
    items: [
      {
        id: 1,
        name: "Авиакомпания",
      },
      {
        id: 2,
        name: "Магазин ёлочных игрушек",
      },
      {
        id: 3,
        name: "Журнал",
      },
      {
        id: 4,
        name: "Парк развлечений",
      },
    ],
  },
  {
    id: 100,
    type: "quiz",
    name: "Что рекламируют?",
    items: [
      {
        id: 1,
        name: "Службу клининга",
      },
      {
        id: 2,
        name: "Социальная реклама против алкоголизма",
      },
      {
        id: 3,
        name: "Лекарство",
      },
      {
        id: 4,
        name: "Новогодняя вечеринка в ночном клубе",
      },
    ],
  },
];

export { PLAN };
