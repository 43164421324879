import { memo, useContext, useState } from "react";
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";

import { AppContext } from "src/shared/app-context";
import Button from "src/shared/components/Button";
import { customAlphabet } from "nanoid";

import logo from "../assets/logo.png";
import tree from "../assets/tree.png";

// import google from "../assets/icons/google.svg";
// import apple from "../assets/icons/apple.svg";
import Input from "src/shared/components/Input";
// import { MAIN_DOMAIN } from "src/shared/config";

const LOGIN_ERRORS: Record<string, string> = {
  "Invalid login credentials": "Неверный логин или пароль",
  "User not found": "Пользователь не найден",
  "User is disabled": "Пользователь заблокирован",
  "Too many unsuccessful login attempts. Please try again later.":
    "Слишком много неудачных попыток входа. Пожалуйста, попробуйте позже.",
};

function AuthScreen() {
  const analytics = getAnalytics();
  const { client } = useContext(AppContext);

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  async function signInAsGuest() {
    if (client === null) {
      throw new Error("Client is null");
    }

    setLoading(true);
    setError("");

    const existedCredentials = localStorage.getItem("login_credentials");
    if (existedCredentials) {
      const [existedLogin, existedPass] = existedCredentials.split(":");
      const { error } = await client.auth.signInWithPassword({
        email: `${existedLogin}@pitcha.cloud`,
        password: existedPass,
      });
      if (error) {
        localStorage.removeItem("login_credentials");
        setTimeout(() => signInAsGuest(), 1000);
      }
      return;
    }

    logEvent(analytics, "sign_in", { provider: "guest" });
    setUserProperties(analytics, { provider: "guest" });
    const login = customAlphabet("124567890QWRYUSDFGJLN", 6)();
    const password = customAlphabet("124567890QWRYUSDFGJLN", 6)();
    await client.auth.signUp({
      email: `${login}@pitcha.cloud`,
      password,
    });
    localStorage.setItem("login_credentials", `${login}:${password}`);

    setLoading(false);
  }

  async function signInWithCredentials() {
    if (client === null) {
      throw new Error("Client is null");
    }
    logEvent(analytics, "sign_in", { provider: "pass" });
    setUserProperties(analytics, { provider: "pass" });

    setLoading(true);
    setError("");
    // await client.auth.signUp({
    //   email: `${login}@pitcha.cloud`,
    //   password,
    // });
    const { error } = await client.auth.signInWithPassword({
      email: `${login}@pitcha.cloud`,
      password,
    });
    setLoading(false);

    if (error) {
      const message = LOGIN_ERRORS[error.message]
        ? LOGIN_ERRORS[error.message]
        : error.message;
      setError(message);
    }
  }

  const canLogin = login.length > 4 && password.length > 5;
  const isGuestLogin = window.location.hash !== "#admin";
  const showGuestLogin = true; //window.location.hash === "#login";

  return (
    <>
      <div className="relative flex flex-col justify-center container min-h-screen max-w-md mx-auto px-6 text-primary py-12">
        <div className="flex flex-col">
          <img
            src={logo}
            className="flex w-40 mx-auto mb-8 animate-show-in-from-top"
            alt="иконка"
          />

          <h1 className="text-white text-center text-lg font-semibold mb-8 animate-show-in-from-top animation-delay-100">
            29 декабря в 18:30 по Московскому времени соберемся здесь большой
            семьей «РИТЭК» и встретим Новый 2023 год!
          </h1>

          <div className="flex flex-col bg-white p-6 rounded-xl space-y-4 animate-show-in-from-top animation-delay-300">
            <div className="text-black text-sm font-medium">
              <div className="flex w-full mb-2">
                <span className="text-2xl w-5 h-5 mr-2 rounded-full flex-shrink-0 mt-0.5">
                  <img src={tree} className="w-5" alt="" />
                </span>
                <p>Поздравим коллег из разных городов</p>
              </div>
              <div className="flex w-full mb-2">
                <span className="text-2xl w-5 h-5 mr-2 rounded-full flex-shrink-0 mt-0.5">
                  <img src={tree} className="w-5" alt="" />
                </span>
                <p>Насладимся живой музыкой по Вашим заказам</p>
              </div>
              <div className="flex w-full mb-2">
                <span className="text-2xl w-5 h-5 mr-2 rounded-full flex-shrink-0 mt-0.5">
                  <img src={tree} className="w-5" alt="" />
                </span>
                <p>
                  Разыграем телевизор, кофемашину, робота для мойки окон,
                  коптильню и многое другое
                </p>
              </div>
              <div className="flex w-full mb-2">
                <span className="text-2xl w-5 h-5 mr-2 rounded-full flex-shrink-0 mt-0.5">
                  <img src={tree} className="w-5" alt="" />
                </span>
                <p>
                  На заработанные баллы выберем подарки с логотипом нашей
                  любимой компании!
                </p>
              </div>
            </div>

            {isGuestLogin ? (
              <>
                {showGuestLogin ? (
                  <Button
                    loading={loading}
                    title="Зарегистрироваться"
                    onClick={signInAsGuest}
                  />
                ) : null}
              </>
            ) : (
              <>
                <Input
                  name="login"
                  label="Логин"
                  value={login}
                  type="text"
                  onChange={setLogin}
                  dark
                />
                <Input
                  name="password"
                  label="Пароль"
                  value={password}
                  type="password"
                  onChange={setPassword}
                  dark
                />
                <Button
                  disabled={!canLogin}
                  loading={loading}
                  title="Подключиться"
                  onClick={signInWithCredentials}
                />
                {error !== "" && (
                  <div className="bg-red-100 relative p-4 text-sm text-center rounded-md text-red-700">
                    <p>{error}</p>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="flex w-full items-center justify-center mt-4">
            <a
              href="https://t.me/platform_support"
              target="_blank"
              rel="noreferrer"
              className="text-black text-sm font-medium underline underline-offset-4 animate-show-in-from-top animation-delay-500"
            >
              Чат поддержки
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

const MemoizedAuthScreen = memo(AuthScreen);
export default MemoizedAuthScreen;
