import { memo, useContext, useEffect, useState } from "react";
import { AppContext } from "src/shared/app-context";
import { getAnalytics, logEvent } from "firebase/analytics";
import { ShoppingBag, ShoppingCart } from "react-feather";
import { User } from "src/App";

interface ShopItem {
  id: number;
  title: string;
  qty: number;
  active: boolean;
  price: number;
  sort: number;
  description: string | undefined;
  photo: string | undefined;
}

function ShopScreen({
  status,
  blacklist,
}: {
  status: boolean;
  blacklist: User[];
}) {
  const analytics = getAnalytics();
  const { user, client } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [shopItems, setShopItems] = useState<ShopItem[]>([]);
  const [cartItems, setCartItems] = useState<Number[]>([]);
  const [buyQty, setBuyQty] = useState<Record<number, number>>({});
  const [balance, setBalance] = useState(0);
  const [refresh, setRefresh] = useState({});

  const [maxHeight, setMaxHeight] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        const playerWrap = document.getElementById("player_wrap")!;
        setMaxHeight(playerWrap.clientHeight);
      } else {
        setMaxHeight(0);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    logEvent(analytics, "shop");
  }, [analytics]);

  useEffect(() => {
    const syncer = async () => {
      // Get shop items
      const { data, error } = await client!
        .from("shop_items")
        .select("*")
        .eq("active", true)
        .order("sort", { ascending: true });

      if (error) {
        console.error(error);
        return;
      }

      // Get user balance
      const { data: balanceData, error: balanceError } = await client!
        .from("users")
        .select("points")
        .eq("id", user!.id)
        .single();

      if (balanceError) {
        console.error(balanceError);
        return;
      }

      // Get user cart
      const { data: cartData, error: cartError } = await client!
        .from("cart_items")
        .select("shop_item_id, amount")
        .eq("author_id", user!.id);

      if (cartError) {
        console.error(cartError);
        return;
      }

      // Get buy quantity
      const newBuyQty: Record<number, number> = {};
      const { data: buyStats } = await client!.rpc("get_shop_buys");
      buyStats!.forEach((item: any) => {
        newBuyQty[item.shop_item_id] = item.qty;
      });

      const cartItems = cartData.map((item: any) => item.shop_item_id);
      const updatedBalance = cartData.reduce(
        (acc, item: any) => acc - item.amount,
        balanceData.points ?? 0
      );

      setBuyQty(newBuyQty);
      setCartItems(cartItems);
      setBalance(updatedBalance);
      setShopItems(data);
      setLoading(false);
    };

    syncer();
  }, [user, client, refresh]);

  const buyItem = async (item: ShopItem) => {
    if (balance < item.price) {
      return;
    }

    // Get buy quantity
    const newBuyQty: Record<number, number> = {};
    const { data: buyStats } = await client!.rpc("get_shop_buys");
    buyStats!.forEach((item: any) => {
      newBuyQty[item.shop_item_id] = item.qty;
    });

    if (newBuyQty[item.id] >= item.qty) {
      alert("Увы, товар закончился");
      setRefresh({});
      return;
    }

    if (!window.confirm(`Купить ${item.title}?`)) {
      return;
    }

    const { error } = await client!.from("cart_items").insert({
      author_id: user!.id,
      shop_item_id: item.id,
      amount: item.price,
    });

    if (error) {
      console.error(error);
    }

    setRefresh({});
  };

  if (!user) {
    throw new Error("Empty user");
  }

  const showShopItems = !loading && status;

  const canUserBuy = !blacklist.map((u) => u.id).includes(user.id);

  return (
    <>
      <div
        style={{ maxHeight: maxHeight > 0 ? maxHeight : undefined }}
        className="lg:col-span-4 relative h-full flex flex-col w-full bg-white rounded-xl text-gray-900 items-center justify-center pt-12"
      >
        <div className="mt-auto w-20 h-20 rounded-full bg-primary flex items-center justify-center">
          <ShoppingBag className="text-white" />
        </div>
        <h2 className="text-xl font-semibold text-primary mt-6">
          {status ? "Магазин открыт" : "Магазин пока закрыт"}
        </h2>
        {status ? (
          <p className="text-sm text-center px-8 mt-2 mb-12">
            {canUserBuy
              ? "Для покупки нажмите на красную кнопку с тележкой на товаре."
              : "У вас уже есть супер-приз, поэтому вы не можете покупать в магазине."}
          </p>
        ) : (
          <p className="text-sm text-center px-8 mt-2 mb-12">
            Магазин откроется в финале мероприятия, чтобы вы могли потратить
            свои баллы.
          </p>
        )}
        <div className="w-full rounded-b-xl p-4 text-white flex flex-col items-center bg-red-500 mt-auto">
          <p className="font-xl font-semibold">{balance}</p>
          <span className="text-xs uppercase text-center tracking-wider mt-0.5">
            ваши баллы
          </span>
        </div>
      </div>
      {showShopItems && (
        <div className="lg:col-span-12 mt-4 bg-white rounded-xl p-4 grid grid-cols-4 gap-4">
          {shopItems.map((item) => {
            const inCart = cartItems.includes(item.id);
            const inCartQty = cartItems.filter((id) => id === item.id).length;
            const alreadyBought = buyQty[item.id] ?? 0;
            const canBuy = balance >= item.price && alreadyBought < item.qty;
            return (
              <div
                key={item.id}
                className="p-4 bg-gray-100 rounded-xl col-span-4 md:col-span-2 lg:col-span-1 flex flex-col relative"
              >
                {inCart && (
                  <div className="absolute right-6 top-6 p-2 bg-green-500 rounded-md text-xs uppercase font-semibold tracking-wider">
                    У вас {inCartQty} шт.
                  </div>
                )}
                <img src={item.photo} alt={item.title} className="rounded-xl" />
                <div className="flex">
                  <div className="flex flex-col mr-2">
                    <p className="text-lg leading-none text-primary font-semibold mt-3">
                      {item.title}
                    </p>
                    <p className="text-xs text-primary mt-0.5">
                      Осталось {Math.max(item.qty - alreadyBought, 0)}
                    </p>
                    <p className="text-xs text-gray-500 mt-1">
                      {item.description}
                    </p>
                  </div>
                  {canUserBuy && (
                    <>
                      {canBuy ? (
                        <button
                          onClick={() => buyItem(item)}
                          className="bg-red-500 flex-shrink-0 flex flex-col items-center justify-center w-14 h-14 mt-3 rounded-full ml-auto"
                        >
                          <ShoppingCart size={16} className="-ml-0.5 mt-0.5" />
                          <span className="text-xs mt-0.5 font-semibold">
                            {item.price}
                          </span>
                        </button>
                      ) : (
                        <button
                          onClick={() =>
                            alert("Недостаточно баллов для покупки")
                          }
                          className="bg-gray-400 flex-shrink-0 flex flex-col items-center justify-center w-14 h-14 mt-3 rounded-full ml-auto"
                        >
                          <ShoppingCart size={16} className="-ml-0.5 mt-0.5" />
                          <span className="text-xs mt-0.5 font-semibold">
                            {item.price}
                          </span>
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

const MemoizedShopScreen = memo(ShopScreen);
export default MemoizedShopScreen;
