import { memo, useCallback, useContext, useEffect, useState } from "react";
import { Message } from "src/features/Chat";
import ChatMessage from "src/features/Chat/ui/ChatMessage";
import OpenedUser from "src/features/OpenedUser/OpenedUser";
import { AppContext } from "src/shared/app-context";
import Header from "src/shared/components/Header";

function QuestionsScreen() {
  const { user, client } = useContext(AppContext);
  const [questions, setQuestions] = useState<Message[]>([]);
  const [loading, setLoading] = useState(true);
  const [openedUser, setOpenedUser] = useState<string | null>(null);

  useEffect(() => {
    const syncer = async () => {
      const { data } = await client!
        .from("chat")
        .select()
        .eq("is_question", true);
      setQuestions(data ?? []);
      setLoading(false);
    };
    if (!client) {
      return;
    }

    const timer = setInterval(() => {
      syncer();
    }, 3000);
    syncer();

    return () => clearInterval(timer);
  }, [client]);

  const onHideQuestion = useCallback(
    async (messageId: number) => {
      await client!
        .from("chat")
        .update({ is_question: false })
        .eq("id", messageId);
    },
    [client]
  );

  let openedUserPreData;

  if (openedUser) {
    const messageFromThisAuthor = questions.find(
      (m) => m.author_id === openedUser
    );
    if (messageFromThisAuthor) {
      // Should be
      openedUserPreData = {
        name: messageFromThisAuthor.author_name,
        photo: messageFromThisAuthor.author_photo,
        type: messageFromThisAuthor.author_type,
      };
    }
  }

  return (
    <>
      <OpenedUser
        userId={openedUser}
        onClose={() => setOpenedUser(null)}
        preData={openedUserPreData}
      />
      <div className="container mx-auto px-6 text-white mt-8 pb-24">
        <Header />
        <div className="relative flex flex-col bg-gray-200 w-full rounded-xl overflow-hidden text-gray-900 p-2">
          <h1 className="text-xl font-semibold p-3 pb-0">Вопросы</h1>
          <p className="text-sm px-3 pb-5 text-gray-400">Новые сверху</p>
          {loading && (
            <div className="relative w-full flex items-center justify-center h-20 mb-8">
              <div className="absolute w-6 h-6 border-2 border-t-transparent rounded-full animate-spin border-primary left-1/2 top-1/2 -m-3"></div>
            </div>
          )}

          <div className="flex flex-col">
            {questions.length === 0 && !loading && (
              <p className="text-base px-3 pb-6">Пока нет вопросов 🤷‍♂️</p>
            )}
            {questions
              .sort((a, b) => b.id - a.id)
              .map((m) => {
                return (
                  <ChatMessage
                    key={m.id}
                    onOpenUser={() => setOpenedUser(m.author_id)}
                    message={m}
                    onSetQuestion={() => onHideQuestion(m.id)}
                    user={user!}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}

const MemoizedQuestionsScreen = memo(QuestionsScreen);
export default MemoizedQuestionsScreen;
