import { memo, useContext, useEffect, useRef, useState } from "react";
import { User } from "src/App";
import { AppContext } from "src/shared/app-context";
import star from "src/assets/icons/star.svg";
import heart from "src/assets/icons/heart.svg";
import { X, Instagram } from "react-feather";
import { ADMINS } from "src/shared/config";

import { getAnalytics, logEvent } from "firebase/analytics";

type UsersCache = Record<string, User>;

function OpenedUser({
  userId,
  preData,
  onClose,
}: {
  userId: string | null;
  preData?: {
    name: string;
    photo: string;
    type: string;
  };
  onClose: () => void;
}) {
  const analytics = getAnalytics();

  const { client, user } = useContext(AppContext);
  const [loadedUser, setLoadedUser] = useState<User | null>(null);
  const cache = useRef<UsersCache>({});

  if (ADMINS.includes(user!.id) && userId) {
    console.log("OpenedUser", userId);
  }

  useEffect(() => {
    if (!userId) {
      setLoadedUser(null);
      return;
    }

    if (cache.current[userId]) {
      setLoadedUser(cache.current[userId]);
      return;
    }
    const syncer = async () => {
      const { data } = await client!
        .from("users")
        .select("id, type, main_info, tinder, tinder_info")
        .eq("id", userId)
        .single();
      if (data) {
        logEvent(analytics, "open_user", { type: data.type });
        cache.current[userId] = data;
        setLoadedUser(data);
      }
    };

    syncer();
  }, [analytics, client, userId]);

  if (userId === null) {
    return null;
  }

  return (
    <div className="fixed w-full h-full left-0 top-0 z-50">
      <button
        className="absolute w-full h-full bg-black opacity-75"
        onClick={onClose}
      />
      <div className="container my-16 px-6 max-w-md mx-auto overflow-auto h-full">
        <div className="overflow-hidden relative bg-gray-100 rounded-xl flex flex-col items-center p-4 pb-8 text-gray-900">
          <button onClick={onClose} className="absolute right-3 top-3 p-2 z-30">
            <X className="w-5" />
          </button>
          {!!loadedUser ? (
            <>
              <div className="flex w-full items-center">
                <div
                  className="mr-2 w-16 h-16 bg-center rounded-full bg-cover border-2 border-white flex-shrink-0 relative"
                  style={{
                    backgroundImage: `url(${loadedUser.main_info?.photo})`,
                  }}
                >
                  {loadedUser.type === "vip" && (
                    <img
                      className="w-4 h-4 absolute right-0 bottom-0 ring-2 ring-gray-100 rounded-full"
                      src={star}
                      alt="VIP"
                    />
                  )}
                  {loadedUser.type === "tester" && (
                    <img
                      className="w-4 h-4 absolute right-0 bottom-0 ring-2 ring-gray-100 rounded-full"
                      src={heart}
                      alt="Tester"
                    />
                  )}
                </div>
                <div className="flex flex-col">
                  <p className="text-md font-semibold break-words w-full">
                    {loadedUser.main_info?.fname}
                  </p>
                  <div className="flex space-x-2">
                    {/* {!!loadedUser.main_info?.instagram && (
                      <a
                        href={`https://instagram.com/${loadedUser.main_info?.instagram}`}
                        target="_blank"
                        rel="noreferrer"
                        className="tracking-wider mt-1 text-xs px-2.5 rounded-full items-center text-white flex justify-center bg-gradient-to-r from-pink-500 to-purple-700"
                      >
                        <Instagram className="w-3 mr-1" /> INSTA
                      </a>
                    )}
                    {!!loadedUser.main_info?.telegram && (
                      <a
                        href={`https://t.me/${loadedUser.main_info?.telegram}`}
                        target="_blank"
                        rel="noreferrer"
                        className="tracking-wider mt-1 text-xs px-2.5 rounded-full items-center text-white flex justify-center bg-gradient-to-r from-cyan-500 to-blue-700"
                      >
                        <Send className="w-3 mr-1" /> TG
                      </a>
                    )} */}
                  </div>
                </div>
              </div>

              {loadedUser.type === "vip" && (
                <>
                  <div className="h-8 mb-4"></div>
                  <div className="items-center overflow-hidden absolute left-0 flex w-full h-8 top-24 bg-blue-600">
                    <div className="text-white font-semibold text-xs uppercase tracking-wider whitespace-nowrap flex animate-swipe-left">
                      <div className="w-8">VIP</div>
                      <div className="w-8">VIP</div>
                      <div className="w-8">VIP</div>
                      <div className="w-8">VIP</div>
                      <div className="w-8">VIP</div>
                      <div className="w-8">VIP</div>
                      <div className="w-8">VIP</div>
                      <div className="w-8">VIP</div>
                      <div className="w-8">VIP</div>
                      <div className="w-8">VIP</div>
                      <div className="w-8">VIP</div>
                      <div className="w-8">VIP</div>
                      <div className="w-8">VIP</div>
                      <div className="w-8">VIP</div>
                      <div className="w-8">VIP</div>
                      <div className="w-8">VIP</div>
                    </div>
                  </div>
                </>
              )}

              {loadedUser.type === "tester" && (
                <>
                  <div className="h-8 mb-4"></div>
                  <div className="items-center overflow-hidden absolute left-0 flex w-full h-8 top-24 bg-pink-500">
                    <div className="text-white font-semibold text-xs uppercase tracking-wider whitespace-nowrap flex animate-swipe-left">
                      <div className="w-32">Прожекторята</div>
                      <div className="w-32">Прожекторята</div>
                      <div className="w-32">Прожекторята</div>
                      <div className="w-32">Прожекторята</div>
                      <div className="w-32">Прожекторята</div>
                    </div>
                  </div>
                </>
              )}

              {/* {loadedUser.tinder && (
                <div className="w-full h-full flex flex-col rounded-md p-3 mt-4 bg-white overflow-auto">
                  {!!loadedUser.tinder_info?.bio && (
                    <div className="flex mb-2 break-words w-full overflow-hidden">
                      <div className="w-5 h-5 rounded-full mr-2 items-center text-white flex justify-center flex-shrink-0 bg-indigo-600">
                        <Star size={14} />
                      </div>
                      <p className="break-words text-xs">
                        {loadedUser.tinder_info?.bio}
                      </p>
                    </div>
                  )}

                  {!!loadedUser.tinder_info?.need && (
                    <div className="flex mb-2">
                      <div className="w-5 h-5 rounded-full mr-2 items-center text-white flex justify-center flex-shrink-0 bg-indigo-600">
                        <Search size={14} />
                      </div>
                      <p className="break-words text-xs w-full">
                        <b className="text-pink-500">Ищу:</b>{" "}
                        {loadedUser.tinder_info?.need}
                      </p>
                    </div>
                  )}
                  {!!loadedUser.tinder_info?.power && (
                    <div className="flex mb-2">
                      <div className="w-5 h-5 rounded-full mr-2 items-center text-white flex justify-center flex-shrink-0 bg-indigo-600">
                        <Zap size={14} />
                      </div>
                      <p className="break-words text-xs w-full">
                        <b className="text-pink-500">Суперсила:</b>{" "}
                        {loadedUser.tinder_info?.power}
                      </p>
                    </div>
                  )}
                  {!!loadedUser.tinder_info?.interests.length && (
                    <div className="flex">
                      <div className="w-5 h-5 rounded-full mr-2 items-center text-white flex justify-center flex-shrink-0 bg-indigo-600">
                        <Award size={14} />
                      </div>
                      <p className="break-words text-xs w-full">
                        <b className="text-pink-500">Интересуюсь:</b>{" "}
                        {loadedUser.tinder_info?.interests.join(", ")}
                      </p>
                    </div>
                  )}
                </div>
              )} */}
            </>
          ) : (
            <>
              {preData ? (
                <div className="flex w-full items-center">
                  <div
                    className="mr-2 w-16 h-16 bg-center rounded-full bg-cover border-2 border-white flex-shrink-0 relative"
                    style={{
                      backgroundImage: `url(${preData.photo})`,
                    }}
                  >
                    {preData.type !== "basic" && (
                      <img
                        className="w-4 h-4 absolute right-0 bottom-0 ring-2 ring-gray-100 rounded-full"
                        src={star}
                        alt="VIP"
                      />
                    )}
                  </div>
                  <div className="flex flex-col">
                    <p className="text-md font-semibold break-words w-full">
                      {preData.name}
                    </p>

                    <div className="flex space-x-2">
                      <div className="tracking-wider mt-1 text-xs px-2.5 rounded-full items-center text-white flex justify-center bg-gray-400">
                        <Instagram className="w-3 mr-1" /> INSTA
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="w-16 h-16 bg-center rounded-full bg-cover border-2 border-white flex-shrink-0 relative"></div>
                </>
              )}
              <div className="mt-6 mx-auto w-8 h-8 border-2 rounded-full border-primary border-t-transparent animate-spin"></div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const MemoizedOpenedUser = memo(OpenedUser);
export default MemoizedOpenedUser;
