import { ScheduleItem } from "src/screens/Schedule";

const SPEAKERS: ScheduleItem[] = [
  {
    id: 1,
    time: "10:30",
    title: "Начало эфира",
    speakers: [],
    highlights: [],
    gift: [],
  },
  {
    id: 2,
    time: "10:35",
    title: "Дайджест НТК",
    speakers: [],
    highlights: [],
    gift: [],
  },
  {
    id: 3,
    time: "10:50",
    title: "Интервью с финалистами",
    speakers: [],
    highlights: [],
    gift: [],
  },
  {
    id: 4,
    time: "11:10",
    title: "Онлайн-игра «НОВЫЙ УРОВЕНЬ»",
    speakers: [],
    highlights: [],
    gift: [],
  },
  {
    id: 5,
    time: "11:40",
    title: "Вручение подарков самым активным участникам",
    speakers: [],
    highlights: [],
    gift: [],
  },
  {
    id: 6,
    time: "11:45",
    title: "Финал",
    speakers: [],
    highlights: [],
    gift: [],
  },
];

const SPEAKERS_2: ScheduleItem[] = [
  {
    id: 7,
    time: "14:25",
    title: "Начало эфира",
    speakers: [],
    highlights: [],
    gift: [],
  },
  {
    id: 8,
    time: "14:30",
    title: "Выступление Генерального директора — Олега Ширяева",
    speakers: [],
    highlights: [],
    gift: [],
  },
  {
    id: 9,
    time: "14:40",
    title: "Панельная дискуссия",
    speakers: [
      {
        speaker_photo: "/images/speakers/oleg.png",
        speaker_name: "Олег Ширяев",
        job: "Генеральный директор МХК ЕвроХим",
      },
      {
        speaker_photo: "/images/speakers/nikolaenko.png",
        speaker_name: "Олег Николаенко",
        job: "Заместитель генерального директора. Директор по производственной безопасности МХК ЕвроХим",
      },
      {
        speaker_photo: "/images/speakers/shilov.png",
        speaker_name: "Константин Шилов",
        job: "Директор Дирекции по реализации инвестиционных проектов МХК ЕвроХим",
      },
      {
        speaker_photo: "/images/speakers/roman.png",
        speaker_name: "Роман Филиппов",
        job: "Глава Горнорудного Дивизиона Группы ЕвроХим",
      },
      {
        speaker_photo: "/images/speakers/konstantin.png",
        speaker_name: "Константин Югов",
        job: "Глава Дивизиона Удобрения Группы ЕвроХим",
      },
      {
        speaker_photo: "/images/speakers/kate.png",
        speaker_name: "Екатерина Серебреникова",
        job: "Директор по персоналу МХК ЕвроХим",
      },
    ],
    highlights: [],
    gift: [],
  },
  {
    id: 10,
    time: "15:20",
    title: "Вопросы от зрителей к участникам панельной дискуссии",
    speakers: [],
    highlights: [],
    gift: [],
  },
  {
    id: 11,
    time: "15:40",
    title: "Объявление результатов НТК",
    speakers: [],
    highlights: [],
    gift: [],
  },
  {
    id: 12,
    time: "16:20",
    title: "Вручение подарков самым активным участникам",
    speakers: [],
    highlights: [],
    gift: [],
  },
  {
    id: 13,
    time: "16:25",
    title: "Финал",
    speakers: [],
    highlights: [],
    gift: [],
  },
];

export { SPEAKERS, SPEAKERS_2 };
