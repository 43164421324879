import { isEqual } from "lodash";
import { memo, useContext, useEffect, useRef, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { User } from "src/App";
import AdminPanel from "src/features/AdminPanel";
import { PlanItem } from "src/features/AdminPanel/config";
import Chat, { Message } from "src/features/Chat";
import Obs from "src/features/Obs/Obs";
import VideoPlayer from "src/features/VideoPlayer";
import { AppContext } from "src/shared/app-context";
import Header from "src/shared/components/Header";
import { ADMINS, ROOM_ID } from "src/shared/config";
import ScheduleScreen from "./Schedule";
import ShopScreen from "./Shop";

const DEFAULT_UPDATE_INTERVAL = 3000;

export interface Room {
  active: true;
  chat: {
    items: Message[];
  };
  created_at: Date;
  id: number;
  name: string;
  poll:
    | (PlanItem & {
        results: Record<number | string, number>;
      })
    | null;
  questions: null;
  state: {
    stream_url: string | null;
    timer?: number;
    schedule?: {
      item: number;
      speaker: string | null;
    };
    shop_blacklist?: User[];
    shop?: boolean;
    chat_link?: {
      url: string;
      label: string;
    };
  } | null;
  update_interval: number;
}

function LiveScreen() {
  const { user, client } = useContext(AppContext);
  const [room, setRoom] = useState<Room | null>(null);
  const lastRoomState = useRef<Room | null>(null);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const syncer = async () => {
      // console.log("[ LiveScreen ] Syncing");
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      const { data, error } = await client!
        .from("rooms")
        .select()
        .eq("id", ROOM_ID)
        .single();

      if (!data || error) {
        throw new Error("Room not found");
      }
      if (!isEqual(data, lastRoomState.current)) {
        setRoom(data);
        lastRoomState.current = data;
      }

      const updateInterval = data.update_interval ?? DEFAULT_UPDATE_INTERVAL;
      timeout.current = setTimeout(() => syncer(), updateInterval);
    };

    syncer();

    // const roomListener = client!
    //   .channel("room")
    //   .on(
    //     "postgres_changes",
    //     {
    //       event: "UPDATE",
    //       schema: "public",
    //       table: "rooms",
    //       filter: `id=eq.${ROOM_ID}`,
    //     },
    //     (payload: any) => {
    //       // Next need because payload new missed some fields sometimes
    //       const newRoom = {
    //         ...lastRoomState.current,
    //         ...payload.new,
    //       };
    //       setRoom(newRoom);
    //       lastRoomState.current = newRoom;
    //     }
    //   )
    //   .subscribe();

    // return () => {
    //   client!.removeChannel(roomListener);
    // };
  }, [client]);

  if (!room) {
    return (
      <div className="container max-w-md mx-auto px-6 text-white mt-8">
        <Header />
        <div className="mt-24 mx-auto w-10 h-10 border-2 rounded-full border-primary border-t-transparent animate-spin"></div>
      </div>
    );
  }

  const isAdmin = user && ADMINS.includes(user.id);
  const showObs = window.location.hash === "#obs" && isAdmin;

  if (showObs) {
    return <Obs room={room} />;
  }

  return (
    <>
      <div className="container mx-auto px-6 text-white pt-4 pb-24">
        <div className="flex flex-col w-full full-container-h">
          <Header />
          <div className="relative gap-4 w-full flex flex-col lg:grid lg:grid-cols-12 my-auto">
            <VideoPlayer state={room.state} />

            <Routes>
              <Route
                path="/"
                element={
                  <div className="lg:col-span-4">
                    <Chat
                      timer={room.state?.timer}
                      link={room.state?.chat_link}
                      poll={room.poll}
                      messages={room.chat?.items ?? []}
                    />
                  </div>
                }
              />
              <Route
                path="schedule"
                element={
                  <div className="lg:col-span-4">
                    <ScheduleScreen state={room.state?.schedule} />
                  </div>
                }
              />
              <Route
                path="shop"
                element={
                  <ShopScreen
                    status={!!room.state?.shop}
                    blacklist={room.state?.shop_blacklist ?? []}
                  />
                }
              />
            </Routes>
            {isAdmin && <AdminPanel />}
          </div>
        </div>
      </div>
    </>
  );
}

const MemoizedLiveScreen = memo(LiveScreen);
export default MemoizedLiveScreen;
