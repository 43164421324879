import { memo, useContext, useEffect, useState } from "react";
import { SPEAKERS, SPEAKERS_2 } from "src/features/Schedule/config";
import { AppContext } from "src/shared/app-context";
import { getAnalytics, logEvent } from "firebase/analytics";
import { ADMINS, ROOM_ID } from "src/shared/config";
import { Play } from "react-feather";

export interface ScheduleItem {
  id: number;
  time?: string;
  speakers: {
    speaker_name?: string;
    speaker_photo?: string;
    job?: string;
    instagram?: string;
    telegram?: string;
  }[];
  title: string;
  highlights: string[];
  gift: {
    title: string;
    url: string;
  }[];
}

function ScheduleScreen({
  state,
}: {
  state: { item: number; speaker: string | null } | undefined;
}) {
  const analytics = getAnalytics();
  const { user, client } = useContext(AppContext);
  const [showOld, setShowOld] = useState(false);
  const [tab, showTab] = useState<30 | 1>(1);

  const [maxHeight, setMaxHeight] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        const playerWrap = document.getElementById("player_wrap")!;
        setMaxHeight(playerWrap.clientHeight);
      } else {
        setMaxHeight(0);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    logEvent(analytics, "schedule");
  }, [analytics]);

  if (!user) {
    throw new Error("Empty user");
  }

  const setScheduleItem = async (item: number, speaker: string | null) => {
    const { data, error } = await client!
      .from("rooms")
      .select()
      .eq("id", ROOM_ID)
      .single();

    if (!data || error) {
      return;
    }

    await client!
      .from("rooms")
      .update({
        state: {
          ...data.state,
          schedule: {
            item,
            speaker,
          },
        },
      })
      .eq("id", ROOM_ID);
  };

  const currentItem = state?.item ?? 0;
  const currentSpeaker = state?.speaker ?? null;

  return (
    <div
      style={{ maxHeight: maxHeight > 0 ? maxHeight : undefined }}
      className="relative overflow-hidden h-full flex aspect-[3/4] md:aspect-square w-full flex-col space-y-2 bg-gray-100 rounded-xl text-gray-900"
    >
      <div className="flex">
        {[30, 1].map((day) => {
          return (
            <button
              key={day}
              onClick={() => showTab(day as 1 | 30)}
              className={`flex-1 p-2 text-center text-sm font-semibold ${
                tab === day ? "bg-gray-100" : "bg-gray-200"
              }`}
            >
              {day} {day === 30 ? "ноября" : "декабря"}
            </button>
          );
        })}
      </div>
      <div className="flex flex-col space-y-4 h-full w-full overflow-auto px-4 pb-6 pt-2">
        {currentItem > 1 && !showOld && (
          <button
            onClick={() => setShowOld(true)}
            className="bg-white rounded-xl p-4 shadow-xl flex flex-col"
          >
            <p className="text-sm text-secondary font-semibold text-center w-full">
              Показать прошедшее
            </p>
          </button>
        )}
        {(tab === 30 ? SPEAKERS : SPEAKERS_2).map((item) => {
          if (item.id < currentItem && !showOld) {
            return null;
          }
          return (
            <div
              key={item.id}
              className={`${
                item.id === currentItem ? "border border-secondary" : ""
              } bg-white rounded-xl p-4 shadow-xl flex flex-col`}
            >
              <div className="w-full flex space-x-2 items-center">
                <div className="relative w-12 text-xs font-semibold flex-shrink-0 h-12 border rounded-full flex items-center justify-center">
                  {item.time}
                </div>
                <div className="flex flex-col w-full">
                  <h3 className="text-base font-semibold">
                    {item.title}{" "}
                    {currentItem === item.id && (
                      <div className="inline-block w-2 h-2 rounded-full bg-secondary mb-1 ml-0.5">
                        <div className="w-2 h-2 rounded-full bg-secondary animate-ping"></div>
                      </div>
                    )}
                  </h3>
                  {item.highlights.length > 0 && (
                    <p className="text-xs text-gray-800">
                      {item.highlights.join(", ")}
                    </p>
                  )}
                </div>
                {ADMINS.includes(user.id) && (
                  <button
                    onClick={() => setScheduleItem(item.id, null)}
                    className="flex-shrink-0 ml-auto w-8 h-8 rounded-md border flex items-center justify-center"
                  >
                    <Play className="w-4" />
                  </button>
                )}
              </div>
              {item.speakers.map((speaker) => (
                <>
                  <hr className="my-2" />
                  <div className="flex w-full space-x-2 items-center">
                    {speaker.speaker_photo && (
                      <img
                        src={speaker.speaker_photo}
                        alt={speaker.speaker_name}
                        className="w-12 h-12 rounded-full"
                      />
                    )}
                    <div
                      className={`${
                        speaker.speaker_name === currentSpeaker
                          ? "text-secondary"
                          : ""
                      } flex flex-col w-full`}
                    >
                      <h3 className="text-sm font-semibold">
                        {speaker.speaker_name}
                      </h3>
                      <p className="text-xs opacity-80">{speaker.job}</p>
                    </div>
                    {ADMINS.includes(user.id) && (
                      <button
                        onClick={() =>
                          setScheduleItem(item.id, speaker.speaker_name!)
                        }
                        className="flex-shrink-0 ml-auto w-8 h-8 rounded-md border flex items-center justify-center"
                      >
                        <Play className="w-4" />
                      </button>
                    )}
                  </div>
                </>
              ))}
              <div className="-ml-20 font-semibold shadow-xl shadow-primary/10 rounded-full py-1 text-sm absolute bottom-4 left-1/2 w-40 px-2 bg-primary text-white text-center">
                Connect to Zoom
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const MemoizedScheduleScreen = memo(ScheduleScreen);
export default MemoizedScheduleScreen;
