import { SupabaseClient } from "@supabase/supabase-js";
import { createContext } from "react";
import { User } from "src/App";

interface AppContextProps {
  client: SupabaseClient | null;
  user: User | null;
  setUser: (user: User | null) => void;
  blacklist: string[];
  setBlacklist: (blacklist: string[]) => void;
}

const defaultContext: AppContextProps = {
  client: null,
  user: null,
  setUser: () => {},
  blacklist: [],
  setBlacklist: () => {},
};

export const AppContext = createContext(defaultContext);
