const ARTYOM = "0dc3d6b2-da9b-4641-ba93-62c91854359c";
const SASHA = "a396233f-cc65-467c-b175-361f62895652";
const ROMA = "22";

const ADMINS = [ARTYOM, SASHA, ROMA];

const MODERATORS = [ARTYOM, SASHA, ROMA];

const CAN_SHOW_QUESTIONS = [ARTYOM, SASHA, ROMA];

const ROOM_ID = 1;
const MAIN_DOMAIN = "dpr-online.ru";

export { ADMINS, MODERATORS, CAN_SHOW_QUESTIONS, ROOM_ID, MAIN_DOMAIN };
