import { ADMINS } from "src/shared/config";
import { Message } from "..";
import { DIRTY_WORDS } from "../config";

// id: number;
// created_at: Date;
// author_id: string;
// author_name: string;
// author_photo: string;
// message: string;
// hidden: boolean;
// tmp?: boolean;

function validateMessages(messages: Message[]): Message[] {
  return messages.map((m) => {
    if (ADMINS.includes(m.author_id)) {
      return m;
    }

    if (m.hidden) {
      return m;
    }

    if (/https?:\/\//gm.test(m.message)) {
      return { ...m, hidden: true };
    }

    if (DIRTY_WORDS.some((w) => m.message.includes(w))) {
      return {
        ...m,
        hidden: true,
      };
    }

    return m;
  });
}

export { validateMessages };
