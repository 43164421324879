import React, { memo } from "react";

interface ButtonProps {
  title: string;
  white?: boolean;
  loading?: boolean;
  disabled?: boolean;
  onClick: () => void;
  className?: string;
}

function Button({
  title,
  white,
  loading,
  disabled,
  className,
  onClick,
}: ButtonProps) {
  return (
    <button
      disabled={loading || disabled}
      onClick={() => onClick()}
      className={`${disabled ? "opacity-60" : ""} ${
        white ? "text-black border-2" : "text-white bg-primary"
      } ${
        className ? className : ""
      } uppercase italic tracking-wider text-custom px-4 py-4 h-14 flex items-center justify-center text-base font-semibold relative rounded-xl`}
    >
      {loading ? (
        <div className="w-5 h-5 rounded-full border-t-transparent border-2 border-white animate-spin" />
      ) : (
        <span className="relative">{title}</span>
      )}
    </button>
  );
}

const MemoizedButton = memo(Button);
export default MemoizedButton;
