import { memo, useEffect, useState } from "react";

function Timer({
  dateToTimer,
  onFinish,
}: {
  dateToTimer: number;
  onFinish: () => void;
}) {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const now = new Date().getTime();
    const countDownDate = dateToTimer;
    if (countDownDate - now < 0 || window.location.hash === "#skip") onFinish();

    // Update the count down every 1 second
    const interval = setInterval(() => {
      // Get today's date and time
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));

      setHours(
        Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) +
          days * 24
      );
      setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
      setSeconds(Math.floor((distance % (1000 * 60)) / 1000));

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(interval);
        onFinish();
      }
    }, 1000);

    return () => clearTimeout(interval);
  }, [dateToTimer, onFinish]);

  if (window.location.hash === "#skip") return null;

  return (
    <div className="absolute z-10 w-full h-full left-0 top-0 bg-white flex flex-col items-center justify-center rounded-xl">
      <h1 className="text-md font-medium text-center mt-2 mb-1 show-from-top">
        Новогоднее онлайн-шоу
        <br /> стартует через
      </h1>
      <div className="flex text-xl font-medium mt-2">
        <div className="rounded-lg bg-white font-bold italic text-primary ml-2 w-16 h-16 border border-primary/50 flex items-center justify-center show-from-top animation-delay-300">
          {hours}
        </div>
        <div className="rounded-lg bg-white font-bold italic text-primary ml-2 w-16 h-16 border border-primary/50 flex items-center justify-center show-from-top animation-delay-600">
          {minutes}
        </div>
        <div className="rounded-lg bg-white font-bold italic text-primary ml-2 w-16 h-16 border border-primary/50 flex items-center justify-center show-from-top animation-delay-900">
          {seconds}
        </div>
      </div>
      <div className="flex text-xs opacity-50 mt-2 mb-4">
        <div className="ml-2 w-16 flex items-center justify-center show-from-top animation-delay-600">
          часы
        </div>
        <div className="ml-2 w-16 flex items-center justify-center show-from-top animation-delay-900">
          мин
        </div>
        <div className="ml-2 w-16 flex items-center justify-center show-from-top animation-delay-1200">
          сек
        </div>
      </div>
    </div>
  );
}

const MemoizedTimer = memo(Timer);
export default MemoizedTimer;
