import { memo, useCallback, useContext, useState } from "react";
import { Edit2, Trash, Video } from "react-feather";
import { Room } from "src/screens/Live";
import { AppContext } from "src/shared/app-context";
import { ADMINS, ROOM_ID } from "src/shared/config";

import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";

function VideoPlayer({ state }: { state: Room["state"] }) {
  const analytics = getAnalytics();

  const { user, client } = useContext(AppContext);
  const [selectedTab, setSelectedTab] = useState(
    parseInt(localStorage.getItem("videoTab") ?? "0")
  );
  const isAdmin = ADMINS.includes(user!.id);
  const noVideo = state === null || state.stream_url === null;

  const onUpdateStream = useCallback(async () => {
    const newStreamUrl = prompt(
      "Вставьте путь для встройки видео",
      state?.stream_url ?? undefined
    );
    if (newStreamUrl !== null) {
      await client!
        .from("rooms")
        .update({ state: { ...state, stream_url: newStreamUrl } })
        .eq("id", ROOM_ID);
    }
  }, [client, state]);

  const onClearStream = useCallback(async () => {
    const confirmed = window.confirm("Убрать видео у всех пользователей?");
    if (confirmed) {
      await client!
        .from("rooms")
        .update({ state: { ...state, stream_url: null } })
        .eq("id", ROOM_ID);
    }
  }, [client, state]);

  const onChangeTab = useCallback(
    (tab: number) => {
      setSelectedTab(tab);
      logEvent(analytics, "change_tab", { tab: tab + 1 });
      setUserProperties(analytics, { video_tab: tab + 1 });
      localStorage.setItem("videoTab", tab.toString());
    },
    [analytics]
  );

  if (noVideo && !isAdmin) {
    return null;
  }

  if (noVideo && isAdmin) {
    return (
      <button
        onClick={onUpdateStream}
        className="mb-6 rounded-xl overflow-hidden w-full aspect-video border border-gray-700"
      >
        <Video className="mx-auto text-yellow-500" />
      </button>
    );
  }

  const videoUrls = state!.stream_url!.split(":::");

  if (videoUrls.length === 1) {
    return (
      <div
        id="player_wrap"
        className="lg:col-span-8 rounded-xl overflow-hidden w-full aspect-video relative flex-shrink-0"
      >
        <iframe
          className="w-full h-full"
          title="video"
          src={state!.stream_url!}
        ></iframe>
        {isAdmin && (
          <>
            <button
              onClick={onUpdateStream}
              className="flex items-center justify-center w-8 h-8 rounded-full absolute right-4 top-4 shadow-xl bg-primary"
            >
              <Edit2 className="w-4 text-white" />
            </button>
            <button
              onClick={onClearStream}
              className="flex items-center justify-center w-8 h-8 rounded-full absolute right-4 bottom-4 shadow-xl bg-primary"
            >
              <Trash className="w-4 text-white" />
            </button>
          </>
        )}
      </div>
    );
  } else {
    const selectedVideo =
      videoUrls[selectedTab > videoUrls.length - 1 ? 0 : selectedTab];
    return (
      <>
        <div className="col-span-4 lg:col-span-8 w-full flex space-x-2">
          {videoUrls.map((url, index) => (
            <button
              key={url}
              onClick={() => onChangeTab(index)}
              className={`${
                index === selectedTab
                  ? "bg-primary text-white border-primary"
                  : "border-primary text-primary opacity-50"
              } rounded-full border px-2.5 py-1 text-xs `}
            >
              Плеер #{index + 1}
            </button>
          ))}
        </div>
        <div className="col-span-8 lg:col-span-8 rounded-xl overflow-hidden w-full aspect-video relative">
          <iframe
            className="w-full h-full"
            title="video"
            src={selectedVideo}
            frameBorder="0"
          ></iframe>
          {isAdmin && (
            <>
              <button
                onClick={onUpdateStream}
                className="flex items-center justify-center w-8 h-8 rounded-full absolute right-4 top-4 shadow-xl bg-primary"
              >
                <Edit2 className="w-4 text-white" />
              </button>
              <button
                onClick={onClearStream}
                className="flex items-center justify-center w-8 h-8 rounded-full absolute right-4 bottom-4 shadow-xl bg-primary"
              >
                <Trash className="w-4 text-white" />
              </button>
            </>
          )}
        </div>
      </>
    );
  }
}

const MemoizedVideoPlayer = memo(VideoPlayer);
export default MemoizedVideoPlayer;
